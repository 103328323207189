import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import ContentTitle from '../components/ContentTitle';
import Header from '../components/Header';
import { useParams } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner'
import CryptoJS from 'crypto-js';


const DropboxView = () => {
    const { dropboxId } = useParams();
    const [sidebarToggle, setSidebarToggle] = useState(true);
    const apiUrl = process.env.REACT_APP_API_MAIN_URL;
    const [dropboxArray, setDropboxArray] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [dropboxId]);

    useEffect(() => {
        const getDRopboxView = async () => {
            setLoading(true);
            //  Convert number to string
            const numberString = dropboxId.toString();
            // Create MD5 hash
            const md5Hash = CryptoJS.MD5(numberString).toString();

            const requestOptions = {
                method: "GET",
            };
            try {
                const response = await fetch(`${apiUrl}/viewdropboxcontent.json?drop_id=${md5Hash}`, requestOptions);
                const result = await response.json();
                console.log(result.knowledge_details[0], "result");
                setDropboxArray(result.knowledge_details[0]); // Assuming your API returns an array of subjects
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        getDRopboxView();
    }, [apiUrl]);

    const sidebarOpen = () => {
        setSidebarToggle(!sidebarToggle);
    }

    const goBack = () => {
        window.history.back();
    };

    console.log(dropboxId, "dropboxId")
    return (
        <div className="dashboard">
            <Header click={sidebarOpen} />
            <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
            <div className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}>
                <ContentTitle name="Dropbox View" click={sidebarOpen} />
                <div className='card_board'>
                    {loading ? (
                        <ThreeDots
                            visible={true}
                            height="80"
                            width="80"
                            color="#8e008e"
                            radius="9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
                            wrapperClass=""
                        />
                    ) : (
                        <>
                            <div className='content_card_title flex-view'>{dropboxArray?.description}<button className='back-btn' onClick={goBack}>Back</button></div>
                            <div className='form-container'>
                                <div className="drobbox-body">
                                    <h5 style={{ marginTop: "0px" }}>Description: <span style={{ fontWeight: "lighter" }}>{dropboxArray?.description}</span></h5>
                                    <div className='iframe-container'>
                                        <iframe src={dropboxArray?.iframelink} title="iframe link"></iframe>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default DropboxView;
