import React, { useEffect, useState } from "react";
import "../css/style.css";
import Header from "../components/Header";
import { useMessages } from "../context/MessageContext";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import { Link } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import dummyImage from "../images/logo.png";

const HowItWorks = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [communityFilter, setCommunityFilter] = useState("newest");
  const [titleFilter, setTitleFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const limit = 10;
  const [classes, setClasses] = useState([]); // State for classes
  const [subjects, setSubjects] = useState([]);
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const SubApiUrl = process.env.REACT_APP_API_NODE_URL;
  const { showSuccess, showError, clearMessages } = useMessages();
  const studentData = JSON.parse(localStorage.getItem("student"));
  const [classId, setClassId] = useState("");
  const [title, setTitle] = useState([]);
  const [howItWorks, setHowItWork] = useState([]);

  const SERVERIMGURL = "https://you-me-globaleducation.org/school/img";

  const handleCommunityChange = (event) => {
    setCommunityFilter(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitleFilter(event.target.value);
  };

  useEffect(() => {
    const getHowItWork = async () => {
      setLoading(true);
      const formData = new FormData();
      formData.append("title", titleFilter);
      formData.append("filter", communityFilter);
      console.log(titleFilter, communityFilter);
      const requestOptions = {
        method: "POST",
        body: formData,
      };
      try {
        const response = await fetch(
          `${apiUrl}/howitworks.json`,
          requestOptions
        );
        const result = await response.json();
        console.log("iny", result);
        setHowItWork(result);
      } catch (error) {
        console.error(error);
        showError(error);
      } finally {
        setLoading(false);
      }
    };

    getHowItWork();
  }, [apiUrl, titleFilter, communityFilter]);

  // // class list api
  useEffect(() => {
    const getClasses = async () => {
      const formData = new FormData();
      formData.append("school_id", studentData?.school_id);

      const requestOptions = {
        method: "GET",
      };
      try {
        const response = await fetch(
          `${apiUrl}/classlist.json?school_id=${studentData?.school_id}`,
          requestOptions
        );
        const result = await response.json();
        console.log(result);
        setClasses(result.classes); // Assuming your API returns an array of classes
      } catch (error) {
        console.error(error);
      }
    };

    getClasses();
  }, [apiUrl]);

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="You-Me Academy" click={sidebarOpen} />
        <div className="card_board">
          <div className="content_card_title">
            How It Works
            <div className="selectboxes">
              <select
                className="form-control community_filter select2-hidden-accessible"
                id="comm_filter"
                onChange={handleCommunityChange}
                value={communityFilter}
              >
                <option value="newest">Newest</option>
                <option value="pdf">PDF</option>
                <option value="video">Video</option>
                <option value="word">Word Document</option>
                <option value="audio">Audio</option>
              </select>
              <button className="back-btn" onClick={goBack}>
                Back
              </button>
            </div>
          </div>
          <div className="form-container">
            <div className="drobbox-body">
              <select
                className="form-control js-states chosetitle select2-hidden-accessible"
                id="title_filter"
                onChange={handleTitleChange}
                value={titleFilter}
              >
                <option value="">Choose Title</option>
                {howItWorks?.title_details?.map((t) => (
                  <option key={t} value={t?.title}>
                    {t?.title}
                  </option>
                ))}
              </select>

              {loading ? (
                <ThreeDots
                  visible={true}
                  height="80"
                  width="80"
                  color="#8e008e"
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  wrapperClass=""
                />
              ) : (
                // <p>Loading...</p>
                <div className="data-body">
                  <div className="data-body-row" id="viewcommunity">
                    {howItWorks?.know_details?.map((work) => {
                      console.log(work.id, "idddddddddddd");
                      return (
                        <div
                          className="col-sm-2 col_img"
                          key={work.id}
                          style={{ marginBottom: "20px" }}
                        >
                          <Link
                            to={`/dashboard/student-knowledge/how-it-works/view/${work.id}`}
                            className="viewknow"
                          >
                            {work?.image !== "" ? (
                              <img
                                src={`${SERVERIMGURL}/${work?.image}`}
                                alt="Dropbox Icon"
                              />
                            ) : (
                              <img src={dummyImage} alt="Dropbox Icon" />
                            )}
                            <div className="set_icon">
                              <i
                                className={`${
                                  work?.file_type === "pdf"
                                    ? "bi bi-file-pdf"
                                    : work?.file_type === "video"
                                    ? " bi bi-camera-video"
                                    : work?.file_type === "audio"
                                    ? "bi bi-headphones"
                                    : "bi bi-filetype-doc"
                                }`}
                              ></i>
                            </div>
                          </Link>
                          <p className="title" style={{ color: "#000" }}>
                            <b>Titre</b>: {work.title}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {/* {!howItWorks?.length ? <h3 style={{ color: "gray", margin: "0" }}>Data Not Found</h3> : ""} */}
              <form
                method="post"
                acceptCharset="utf-8"
                action="/school/Dropbox/"
                style={{ display: "none" }}
              >
                <div>
                  <input type="hidden" name="_method" value="POST" />
                  <input
                    type="hidden"
                    name="_csrfToken"
                    autoComplete="off"
                    value="fd82b978dfb069d344e73b0d4e9dca020ee9d23fbca43988689186f03e216fc58422d41d5d040c943c9761c1bcb0be38cb915d43025ce94bebffabf8685a6482"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
