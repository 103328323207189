// PdfModal.js
import React from 'react';
import Modal from 'react-modal';
import html2pdf from 'html2pdf.js';

Modal.setAppElement('#root'); // For accessibility

const PdfModal = ({ isOpen, onRequestClose, pdfContent }) => {
  const generatePdf = () => {
    const element = document.getElementById('pdf-content');
    const opt = {
      margin: 1,
      filename: 'assignment.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    html2pdf().from(element).set(opt).save();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="PDF Modal"
      className="modal"
      overlayClassName="overlay"
    >
      <div className="modal-header">
        <h2>Submitted Assignment</h2>
        <button onClick={onRequestClose}>Close</button>
        <button onClick={generatePdf}>Download PDF</button>
      </div>
      <div id="pdf-content" className="pdf-content">
        {pdfContent}
      </div>
    </Modal>
  );
};

export default PdfModal;
