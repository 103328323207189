import React, { useState } from 'react';
import '../css/login.css';
import bgLogo from '../images/bg-logo.png';
import youMeLive from '../images/you-me-live.png';
import logo from '../images/logo.png';
import axios from 'axios'; // Import axios
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Login = () => {
  // State to store form data
  const [validation, setValidation] = useState("");
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [language, setLanguage] = useState('choose-language');
  const [rememberMe, setRememberMe] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();


  const handleSubmit = async (event) => {
    event.preventDefault();
    await login(email, password, language, navigate);
    setValidation(localStorage.getItem('error'));
  };

  console.log(validation, "validation")


  return (
    <section>
      <div className="container">
        <div className="login-banner">
          <div className="login-images">
            <img src={bgLogo} alt="Background Logo" />
            <img src={youMeLive} alt="You Me Live" />
          </div>
          <div className="login-box">
            <div className="login-content">
              <h4>Nice To Meet You Again</h4>
              <h2>WELCOME BACK!</h2>
            </div>
            <div className="login-form">
              <img src={logo} alt="Logo" />
              <form onSubmit={handleSubmit}>
                <input
                  className="email"
                  type="text"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <input
                  className="password"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <select
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                >
                  <option value="choose-language">Choose A Language</option>
                  <option value="french">French</option>
                  <option value="english">English</option>
                </select>
                <label htmlFor="me">
                  Remember Me
                  <input
                    name="me"
                    id="me"
                    type="checkbox"
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                </label>
                {validation !== "" ? <p style={{color: "red", textAlign: "center", margin: '0'}}>{validation}</p> : null}
                <input type="submit" value="Login" />
              </form>
              <div className="help-and-password">
                <Link to="#">Forget Password?</Link>
                <span>|</span>
                <Link to="#">Need Help?</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
