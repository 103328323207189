import React, { useContext, useEffect, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // For drag and drop, selecting
import CryptoJS from "crypto-js";
import { useMessages } from "../context/MessageContext";
import { Link } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

const SubjectAttendance = () => {
  const [loading, setLoading] = useState(false);
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const [events, setEvents] = useState([]);

  const [subjects, setSubjects] = useState([]);
  const { showSuccess, showError, clearMessages } = useMessages();
  const [subjectID, setSubjectID] = useState("");
  const apiUrlNode = process.env.REACT_APP_API_NODE_URL;
  const [currentDate, setCurrentDate] = useState("");



  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const goBack = () => {
    window.history.back();
  };

    // class
    useEffect(() => {
      const fetchEvents = async () => {
        setLoading(true);
        const sessionData = await JSON.parse(localStorage.getItem("student"));
        //  Convert number to string
        const numberString = sessionData?.id?.toString();
        // Create MD5 hash
        const md5Hash = CryptoJS.MD5(numberString).toString();
  
        const formData = new FormData();
        formData.append("student_id", md5Hash);
  
        const requestOptions = {
          method: "POST",
          body: formData,
        };
  
        try {
          const response = await fetch(
            `${apiUrl}/classattendence.json`,
            requestOptions
          );
          const data = await response.json();
          console.log(data, "data");
          // Map API response to FullCalendar events format
          const formattedEvents = data.attendence.map(event => ({
            title: event.attendance,
            date: event.date,
          }));
          setEvents(formattedEvents);
        } catch (error) {
          console.error("Error fetching events:", error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchEvents();
    }, [apiUrl]);

  // all subjects
  useEffect(() => {
    const getSubjects = async () => {
      setLoading(true);
      const studentData = await JSON.parse(localStorage.getItem("student"));

      const requestOptions = {
        method: "GET",
      };

      try {
        const response = await fetch(
          `${apiUrlNode}/subject/subjects?school_id=${studentData?.school_id}&student_id=${studentData?.id}`,
          requestOptions
        );
        const result = await response.json();
        setSubjects(result); // Assuming your API returns an array of subjects
        showSuccess(result?.message);
        setSubjectID(result[17]?.id);
      } catch (error) {
        console.error(error);
        showError(error?.status + error?.message);
      } finally {
        setLoading(false);
        setTimeout(() => {
          clearMessages();
        }, 3000);
      }
    };

    getSubjects();
  }, [apiUrlNode]);

  // subjectwise data api
    const SubjectWiseData = async (id) => {
      setLoading(true);
      setSubjectID(id)
      const sessionData = await JSON.parse(localStorage.getItem("student"));
      //  Convert number to string
      const numberString = sessionData?.id?.toString();
      // Create MD5 hash
      const md5Hash = CryptoJS.MD5(numberString).toString();

      const formData = new FormData();
      formData.append("student_id", md5Hash);
      formData.append("class_id", sessionData?.class);
      formData.append("subject_id", id);

      const requestOptions = {
        method: "POST",
        body: formData,
      };

      try {
        const response = await fetch(
          `${apiUrl}/subjectattendence.json`,
          requestOptions
        );
        const data = await response.json();
        console.log(data, "calender data");
        // Map API response to FullCalendar events format
        const formattedEvents = data.attendence.map(event => ({
          title: event.attendance,
          date: event.date,
        }));
        setEvents(formattedEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };
    // try start
    useEffect(() => {
      // Format current date to YYYY-MM-DD
      const today = new Date();
      const formattedDate = today.toISOString().split("T")[0];
      setCurrentDate(formattedDate);
    }, []);
  
    useEffect(() => {
      // Fetch events or data here
      const fetchEvents = async () => {
        try {
          // Replace with your event fetching logic
          const response = await fetch("your-api-endpoint");
          const data = await response.json();
          console.log("Fetched events data:", data); // Debugging line
          if (Array.isArray(data.events)) {
            setEvents(data.events);
          } else {
            console.error("Unexpected data structure:", data);
          }
        } catch (error) {
          console.error("Error fetching events:", error);
        }
      };
  
      fetchEvents();
    }, []);
  
    useEffect(() => {
      if (currentDate && events.length) {
        const applyStylesToEvents = () => {
          events.forEach(event => {
            if (event.date && event.title) {
              const tdElement = document.querySelector(`td[data-date="${event.date}"]`);
              if (tdElement) {
                tdElement.classList.remove("Present", "Absent");
  
                if (event.title === "Present") {
                  tdElement.classList.add("Present");
                } else if (event.title === "Absent") {
                  tdElement.classList.add("Absent");
                }
              } else {
                console.error(`No <td> element found for date: ${event.date}`);
              }
            } else {
              console.error('Event is missing date or status:', event);
            }
          });
        };
  
        setTimeout(applyStylesToEvents, 100); // Adjust delay if needed
      }
    }, [events, currentDate]);

    const handleEventDidMount = (info) => {
      if (info.event.title === "Present") {
        info.el.style.backgroundColor = "green";
        info.el.style.color = "white";
      } else if (info.event.title === "Absent") {
        info.el.style.backgroundColor = "red";
        info.el.style.color = "white";
      }
    };
    // end try


  // Handle clicking on an event
  const handleEventClick = (clickInfo) => {
    alert(`Clicked on event: ${clickInfo.event.title}`);
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="Attendance" click={sidebarOpen} />
        <div className="card_board">
          <div className="content_card_title flex-view">
            StudentProfile - Attendance - Class Attendance
            <button className="back-btn" onClick={goBack}>
              Back
            </button>
          </div>

          {loading ? (
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#8e008e"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              wrapperClass=""
            />
          ) : (
            <>
              <div className="form-container">
                <div className="attendanceSubjectOptions">
                  {subjects?.map((sub) => (
                    <Link>
                      <button
                        className={`option ${
                          subjectID == sub?.id && "activeSubject"
                        }`}
                        data-target="grades"
                        onClick={() => SubjectWiseData(sub?.id)}
                      >
                        {sub.subject_name}
                      </button>
                    </Link>
                  ))}
                </div>
              </div>
            </>
          )}

          <div style={{ width: "50%", margin: "100px auto" }}>
               <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              events={events}
              editable={true}
              selectable={true}
              eventClick={handleEventClick}
              dateClick={(info) => alert(`Date clicked: ${info.dateStr}`)}
              eventDidMount={handleEventDidMount}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubjectAttendance;
