// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.success-message {
    color: white;
    padding: 10px;
    border: 1px solid green;
    background-color: green;
    border-radius: 5px;
    margin: 10px;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1;
    font-weight: 700;
    min-width: 400px;
    text-align: center;
  }
  
  .error-message {
    color: white;
    padding: 10px;
    border: 1px solid red;
    background-color: red;
    border-radius: 5px;
    margin: 10px;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1;
    font-weight: 700;
    min-width: 400px;
    text-align: center;
  }
  `, "",{"version":3,"sources":["webpack://./src/css/Message.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,uBAAuB;IACvB,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,SAAS;IACT,QAAQ;IACR,UAAU;IACV,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,qBAAqB;IACrB,qBAAqB;IACrB,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,SAAS;IACT,QAAQ;IACR,UAAU;IACV,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;EACpB","sourcesContent":[".success-message {\n    color: white;\n    padding: 10px;\n    border: 1px solid green;\n    background-color: green;\n    border-radius: 5px;\n    margin: 10px;\n    position: fixed;\n    bottom: 0;\n    right: 0;\n    z-index: 1;\n    font-weight: 700;\n    min-width: 400px;\n    text-align: center;\n  }\n  \n  .error-message {\n    color: white;\n    padding: 10px;\n    border: 1px solid red;\n    background-color: red;\n    border-radius: 5px;\n    margin: 10px;\n    position: fixed;\n    bottom: 0;\n    right: 0;\n    z-index: 1;\n    font-weight: 700;\n    min-width: 400px;\n    text-align: center;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
