import React, { createContext, useState, useContext } from 'react';
import SuccessMessage from '../components/SuccessMessage';
import ErrorMessage from '../components/ErrorMessage';
const MessageContext = createContext();

export const useMessages = () => useContext(MessageContext);

export const MessageProvider = ({ children }) => {
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const showSuccess = (message) => {
    console.log('Show success:', message); // Debugging
    setSuccessMessage(message);
  };
  const showError = (message) => {
    console.log('Show error:', message); // Debugging
    setErrorMessage(message);
  };
  const clearMessages = () => {
    setSuccessMessage('');
    setErrorMessage('');
  };

  return (
    <MessageContext.Provider value={{ showSuccess, showError, clearMessages }}>
      {successMessage && <SuccessMessage message={successMessage} />}
      {children}
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </MessageContext.Provider>
  );
};
