import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import { ThreeDots } from "react-loader-spinner";
import { useMessages } from "../context/MessageContext";
import Pagination from "../components/Pagination";

const StudyAbroad = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [contryValue, setContryValue] = useState("");
  const [data, setData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const [showModal, setShowModal] = useState(false);
  const [dataId, setDataId] = useState(0);
  const [uniName, setUniName] = useState("");
  const [uniPic, setUniPic] = useState("");
  const { showSuccess, showError, clearMessages } = useMessages();
  const [error, setError] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    budget: "",
    academic_year: "",
    message: "",
  });

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const offset = (currentPage - 1) * limit;
      const formDatas = new FormData();
      formDatas.append("filter", contryValue);
      formDatas.append("offset", offset);
      formDatas.append("limit", limit);

      const requestOptions = {
        method: "POST",
        body: formDatas,
      };
      try {
        // all contries api
        const response = await fetch(
          `${apiUrl}/studyabroad.json`,
          requestOptions
        );
        const result = await response.json();

        if (searchTerm !== "") {
          const searchFilter = await result?.univ_details?.filter((e) => {
            return (
              e?.country_name === searchTerm ||
              e?.email === searchTerm ||
              e?.contact_number === searchTerm ||
              e?.website_link === searchTerm
            );
          });
          console.log(searchFilter, "search filter");
          setData(searchFilter);
        } else {
          setData(result?.univ_details);
          console.log(result, "all");
        }
        setCountryData(result?.countries_details);
        setTotalPages(Math.ceil(result.total_records / limit));
      } catch (error) {
        console.log("file error", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [apiUrl, contryValue, currentPage, limit, searchTerm]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const handleCloseModal = () => {
    setFormData({
      name: "",
      email: "",
      contact: "",
      budget: "",
      academic_year: "",
      message: "",
    });
    setShowModal(false);
    setError("");
  };

  const handleShowModal = (id, name, pic) => {
    setDataId(id);
    setUniName(name);
    setUniPic(pic);
    setShowModal(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // contact api
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formDatas = new FormData();
    formDatas.append("name", formData?.name);
    formDatas.append("email", formData?.email);
    formDatas.append("number", formData?.contact);
    formDatas.append("budget", formData?.budget);
    formDatas.append("academic_year", formData?.academic_year);
    formDatas.append("desc", formData?.message);
    formDatas.append("univid", dataId);

    const requestOptions = {
      method: "POST",
      body: formDatas,
    };
    try {
      const response = await fetch(
        `${apiUrl}/studyabroadcontact.json`,
        requestOptions
      );
      const result = await response.json();
      if (response.ok) {
        setError("");
        showSuccess(result?.message);
        setTimeout(() => {
          handleCloseModal();
        }, 2000);
      } else {
        setError(result?.message);
      }
    } catch (error) {
      console.error(error);
      setError(error?.message);
    } finally {
      setLoading(false);
      setTimeout(() => {
        clearMessages();
      }, 3000);
    }
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <div id="main-content1" className="content_container">
          <ContentTitle name="You-Me Academy" click={sidebarOpen} />
          <div className="card_board">
            <div className="content_card_title">
              Universities List{" "}
              <div
                style={{ display: "flex", alignItems: "center" }}
                className="selectboxes"
              >
                <select
                  className="form-control"
                  onChange={(e) => setContryValue(e.target.value)}
                >
                  <option value="">Choose Contry</option>
                  {countryData?.map((v, i) => {
                    if (v?.sortname === "CD") {
                      return null;
                    } else {
                      return (
                        <option value={v.sortname} key={i}>
                          {v.name}
                        </option>
                      );
                    }
                  })}
                </select>
                <button className="back-btn" onClick={goBack}>
                  Back
                </button>
              </div>
            </div>
            <div className="pagination-container"></div>
            <div className="row clearfix" style={{ padding: "0px 20px" }}>
              <div className="col-lg-12">
                <div className="card">
                  <div className="entries-and-search-container">
                    <div className="entries">
                      Show
                      <select
                        className="form-control"
                        value={limit}
                        onChange={handleLimitChange}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                        <option value={40}>40</option>
                        <option value={50}>50</option>
                      </select>
                      entries
                    </div>
                    <div className="entries">
                      Search:
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                  <div className="table-container">
                    <div className="table-responsive">
                      {loading ? (
                        <ThreeDots
                          visible={true}
                          height="80"
                          width="80"
                          color="#8e008e"
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          wrapperClass=""
                        />
                      ) : (
                        // <p>Loading...</p>
                        <table
                          className="unitable table table-hover js-basic-example dataTable table-custom table-striped m-b-0 c_list default_pageitem notification_table no-footer"
                          id="notification_table"
                          data-page-length="50"
                          role="grid"
                          aria-describedby="notification_table_info"
                        >
                          <thead className="thead-dark">
                            <tr role="row">
                              <th
                                className="sorting"
                                tabIndex="0"
                                aria-controls="notification_table"
                                rowSpan="1"
                                colSpan="1"
                                aria-label="Topic: activate to sort column ascending"
                                style={{ width: "611px" }}
                              >
                                Logo
                              </th>
                              <th
                                className="sorting"
                                tabIndex="0"
                                aria-controls="notification_table"
                                rowSpan="1"
                                colSpan="1"
                                aria-label="From: activate to sort column ascending"
                                style={{ width: "800px" }}
                              >
                                University Description
                              </th>
                            </tr>
                          </thead>
                          <tbody id="notificationbody">
                            {data?.length >= 1 ? (
                              data?.map((dataVal, dataIndex) => {
                                if (dataVal?.country_id === "CD") {
                                  return null;
                                } else {
                                  return (
                                    <tr key={dataIndex}>
                                      <td>
                                        <div className="table-image-container-image">
                                          <img
                                            src={`https://you-me-globaleducation.org/school/univ_logos/${dataVal?.logo}`}
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <div className="table-image-container">
                                          <div className="table-top-data-container">
                                            <div className="data">
                                              <h4>{dataVal?.univ_name}</h4>
                                              <div className="text-container">
                                                {" "}
                                                <p>
                                                  <span>Country:</span>
                                                  {dataVal?.country_name}
                                                </p>{" "}
                                              </div>
                                              <div className="text-container">
                                                <p>
                                                  <span>E_Mail:</span>
                                                  {dataVal?.email}
                                                </p>
                                              </div>
                                              <div className="text-container">
                                                <p>
                                                  <span>Contact No:</span>{" "}
                                                  {dataVal?.contact_number}
                                                </p>
                                              </div>
                                              <div className="text-container">
                                                <p>
                                                  <span>Website_Link:</span>
                                                  {dataVal?.website_link}
                                                </p>
                                              </div>
                                            </div>
                                            <div className="data2">
                                              <button
                                                type="submit"
                                                id="editstdntprfbtn"
                                                className="btn btn-primary table-submit"
                                                onClick={() =>
                                                  handleShowModal(
                                                    dataVal?.id,
                                                    dataVal?.univ_name,
                                                    dataVal?.logo
                                                  )
                                                }
                                              >
                                                Contact Us
                                              </button>
                                            </div>
                                          </div>
                                          <div className="table-bottom-description-container">
                                            <div className="text-container">
                                              <p>
                                                <span>Courses Offered:</span>
                                                {dataVal?.academics}
                                              </p>
                                            </div>
                                            {!seeMore && (
                                              <div className="text-container">
                                                <p>
                                                  <span>Description:</span>
                                                  {dataVal?.about_univ?.slice(
                                                    0,
                                                    248
                                                  )}
                                                </p>
                                              </div>
                                            )}
                                            {seeMore && (
                                              <div className="text-container">
                                                <p>
                                                  <span>Description:</span>
                                                  {dataVal?.about_univ}
                                                </p>
                                              </div>
                                            )}
                                            <div
                                              className="see-container"
                                              onClick={() =>
                                                setSeeMore(!seeMore)
                                              }
                                            >
                                              {seeMore ? (
                                                <p>See Less</p>
                                              ) : (
                                                <p>See More</p>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                }
                              })
                            ) : (
                              <tr>
                                <td>No country data found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                  <div className="pagination-container">
                    <p>
                      Show 1 to {limit} of {data?.length} entries
                    </p>
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Custom Modal */}
      {showModal && (
        <div className="custom-modal">
          <div
            className="custom-modal-content"
            style={{ width: "50%", maxWidth: "unset" }}
          >
            <div className="custom-modal-title">
              <span style={{ display: "flex", alignItems: "center" }}>
                {" "}
                Contact Us -
                <div className="modal-img-container">
                  <img
                    src={`https://you-me-globaleducation.org/school/univ_logos/${uniPic}`}
                  />
                </div>
                {uniName}
              </span>{" "}
              <i
                className="bi bi-x-lg"
                onClick={handleCloseModal}
                style={{ cursor: "pointer" }}
              ></i>
            </div>
            <div className="form-container">
              <form
                onSubmit={(e) => handleSubmit(e)}
                encType="multipart/form-data"
                className="contact-modal"
              >
                {/* first row */}
                <div className="form-row" style={{ marginBottom: "10px" }}>
                  <div
                    className="form-group form-control"
                    style={{ width: "45%" }}
                  >
                    <label>Name*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={formData.name}
                      required
                      placeholder="Enter Name"
                      onChange={handleChange}
                    />
                  </div>
                  <div
                    className="form-group form-control"
                    style={{ width: "45%" }}
                  >
                    <label>Email*</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      required
                      value={formData.email}
                      placeholder="Enter Email"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {/* second row */}
                <div className="form-row" style={{ marginBottom: "10px" }}>
                  <div
                    className="form-group form-control"
                    style={{ width: "30%" }}
                  >
                    <label>Contact No.*</label>
                    <input
                      type="number"
                      className="form-control"
                      name="contact"
                      required
                      value={formData.contact}
                      placeholder="Enter Contact No."
                      onChange={handleChange}
                    />
                  </div>
                  <div
                    className="form-group form-control"
                    style={{ width: "30%" }}
                  >
                    <label>Budget*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="budget"
                      required
                      value={formData.budget}
                      placeholder="Enter Budget"
                      onChange={handleChange}
                    />
                  </div>
                  <div
                    className="form-group form-control"
                    style={{ width: "30%" }}
                  >
                    <label>Academic Year *</label>
                    <select
                      required
                      className="form-control"
                      style={{ marginBottom: "0" }}
                      name="academic_year"
                      value={formData.academic_year}
                      onChange={handleChange}
                    >
                      <option value="">Choose One</option>
                      <option value={2021}>2021</option>
                      <option value={2022}>2022</option>
                      <option value={2023}>2023</option>
                      <option value={2024}>2024</option>
                      <option value={2025}>2025</option>
                    </select>
                  </div>
                </div>

                {/* third row */}
                <div className="form-row" style={{ marginBottom: "10px" }}>
                  <div
                    className="form-group form-control"
                    style={{ width: "100%" }}
                  >
                    <label>Messages*</label>
                    <textarea
                      required
                      placeholder="Enter Description"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {/* btn-row */}
                <div className="btn-container">
                  <button
                    type="submit"
                    id="editstdntprfbtn"
                    className="btn btn-primary form-update"
                  >
                    Send
                  </button>
                  <span style={{ margin: "0px 10px" }}></span>
                  <button
                    id="editstdntprfbtn"
                    className="btn btn-primary form-update"
                    onClick={handleCloseModal}
                  >
                    Close
                  </button>
                </div>
                <p style={{ color: "red" }}>{error}</p>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudyAbroad;
