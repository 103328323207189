// src/admin/NationalGeographic.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import ContentTitle from '../components/ContentTitle';

const StudentKnowledge = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  }
  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}>
        <div id="main-content1" className="content_container">
          <ContentTitle name="You-Me Academy" click={sidebarOpen} />
          <div className='card_board'>
            <div className="options">
              <Link to="/dashboard/study-abroad">
                <button className="option" data-target="study_abroad">Study Abroad</button>
              </Link>
              <Link to="/dashboard/local-university">
                <button className="option" data-target="local_university">Local University</button>
              </Link>
              <Link to="/dashboard/student-knowledge/community">
                <button className="option" data-target="you_me_knowledge">You-Me Knowledge Center</button>
              </Link>
              <Link to="/dashboard/student-knowledge/how-it-works">
                <button className="option" data-target="how_works">How it works</button>
              </Link>
              <Link to="/dashboard/student-knowledge/scholarship">
                <button className="option" data-target="scholarship">Scholarship</button>
              </Link>
              <Link to="/dashboard/student-knowledge/mentorship">
                <button className="option" data-target="mentorship">Mentorship</button>
              </Link>
              <Link to="/dashboard/student-knowledge/internship">
                <button className="option" data-target="internship">Internship</button>
              </Link>
              <Link to="/dashboard/student-knowledge/intensive_english">
                <button className="option" data-target="intensive_english">Intensive English</button>
              </Link>
              <Link to="/dashboard/student-knowledge/leadership">
                <button className="option" data-target="leadership">Leadership and Entrepreneurship</button>
              </Link>
              <Link to="/dashboard/student-knowledge/new_technologies">
                <button className="option" data-target="new_technologies">New Technologies</button>
              </Link>
              <Link to="/dashboard/student-knowledge/state_exam">
                <button className="option" data-target="state_exam">State Exam</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentKnowledge;
