import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import Header from "../components/Header";
import "../css/style.css";

const Calendar = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [events, setEvents] = useState([]);
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  useEffect(() => {
    const fetchEvents = async () => {
      const sessionData = await JSON.parse(localStorage.getItem("student"));
      const formData = new FormData();
      formData.append("school_id", sessionData?.school_id);

      const requestOptions = {
        method: "POST",
        body: formData,
      };

      try {
        const response = await fetch(
          `${apiUrl}/calendardata.json`,
          requestOptions
        );
        const data = await response.json();
        setEvents(data);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, [apiUrl]);

  const handleEventClick = (clickInfo) => {
    alert(`Clicked on event: ${clickInfo.event.title}`);
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="Calendar" click={sidebarOpen} />
        <div className="card_board">
          <div className="content_card_title flex-view">
            Student Profile &gt; Calendar{" "}
            <button className="back-btn" onClick={goBack}>
              Back
            </button>
          </div>
          <div
            style={{ width: "50%", marginLeft: "20%", marginBottom: "100px" }}
          >
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              events={{'title': events?.title, 'date': new Date(events?.date).toLocaleDateString()}}
              editable={true}
              selectable={true}
              eventClick={handleEventClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
