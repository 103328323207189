// src/context/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);
const apiUrl = process.env.REACT_APP_API_NODE_URL;

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return localStorage.getItem('isAuthenticated') === 'true';
  });

  const login = async (email, password, language, navigate) => {
    try {
      const data = { email, password, language };
      const response = await axios.post(`${apiUrl}/auth/login`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        setIsAuthenticated(true);
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('student',  JSON.stringify(response.data));
        localStorage.removeItem("error")
        navigate('/dashboard');
      } else {
        setIsAuthenticated(false);
        localStorage.setItem('isAuthenticated', 'false');
        navigate('/');
      }
    } catch (error) {
      console.error('Error logging in:', error.response.data);
      setIsAuthenticated(false);
      localStorage.setItem('isAuthenticated', 'false');
      localStorage.setItem('student', '');
      localStorage.setItem("error", JSON.stringify(error.response.data.error))
      navigate('/');
    }
  };

  const logout = (navigate) => {
    setIsAuthenticated(false);
    localStorage.setItem('isAuthenticated', 'false');
    navigate('/');
  };

  useEffect(() => {
    if (localStorage.getItem('isAuthenticated') === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
