import React, { useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import { Link } from "react-router-dom";

const Attendance = () => {
  const [loading, setLoading] = useState(false);
  const [sidebarToggle, setSidebarToggle] = useState(true);

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="Attendance" click={sidebarOpen} />
        <div className="card_board">
          <div className="content_card_title flex-view">
            StudentProfile - Attendance
            <button className="back-btn" onClick={goBack}>
              Back
            </button>
          </div>
          <div className="form-container">
            <div className="options">
              <Link to="/dashboard/attendance/day">
                <button className="option" data-target="grades">
                  Day Attendance
                </button>
              </Link>
              <Link to="/dashboard/attendance/subject">
                <button className="option" data-target="grades">
                  Subject Attendance
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Attendance;
