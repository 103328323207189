import React from 'react';

export default function ContentTitle({name, click}) {
    return (
        <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
                <h2 className='content_title'>
                    <a href="javascript:void(0);" className="btn btn-xs btn-link btn-toggle-fullwidth">
                        <i className="bi bi-arrow-left" onClick={click}></i>
                    </a>
                    {name}
                </h2>
            </div>
        </div>
    )
}
