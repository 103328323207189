import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import ContentTitle from '../components/ContentTitle';
import Header from '../components/Header';
import { useParams } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import CryptoJS from 'crypto-js'
import { useMessages } from '../context/MessageContext';

const StudyGuideView = () => {
    const { id } = useParams();
    const [sidebarToggle, setSidebarToggle] = useState(true);
    const apiUrl = process.env.REACT_APP_API_NODE_URL;
    const [dropboxArray, setDropboxArray] = useState(null);
    const [loading, setLoading] = useState(false);
    const studentData = JSON.parse(localStorage.getItem('student'));
    const { showSuccess, showError, clearMessages } = useMessages();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [id]);


    useEffect(() => {
        const getDRopboxView = async () => {
            setLoading(true);

            const requestOptions = {
                method: "GET",
            };
            try {
                const response = await fetch(`${apiUrl}/quiz/getQuiz?id=${id}&student_id=${studentData?.id}`, requestOptions);
                const result = await response.json();
                console.log(result, "result");
                setDropboxArray(result[0]); // Assuming your API returns an array of subjects
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        getDRopboxView();
    }, [apiUrl]);



    const sidebarOpen = () => {
        setSidebarToggle(!sidebarToggle);
    }

    const goBack = () => {
        window.history.back();
    };

    return (
        <div className="dashboard">
            <Header click={sidebarOpen} />
            <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
            <div className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}>
                <ContentTitle name="Study Guide" click={sidebarOpen} />
                <div className='card_board'>
                    {loading ? (
                        <ThreeDots
                            visible={true}
                            height="80"
                            width="80"
                            color="#8e008e"
                            radius="9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
                            wrapperClass=""
                        />
                    ) : (
                        <>
                            <div className='content_card_title flex-view'>{dropboxArray?.title}<button className='back-btn' onClick={goBack}>Back</button></div>
                            <div className="library-content-container">
                                <iframe src={dropboxArray?.file_name} title="iframe link"></iframe>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default StudyGuideView;
