import React, { useEffect, useState, useRef } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import { useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { useMessages } from "../context/MessageContext";
import html2pdf from "html2pdf.js";
import schoolLogo from "../images/schoolLogo.png";
import moment from "moment";
import { Document, Page } from "react-pdf";

const GeneratePdf = () => {
  const { id, subject } = useParams();
  const [loading, setLoading] = useState(false);
  const [download, setDownload] = useState(false);
  const apiUrl = process.env.REACT_APP_API_NODE_URL;
  const apiUrlMain = process.env.REACT_APP_API_MAIN_URL;
  const pdfUrl = process.env.REACT_APP_PDF_CHECKER;
  const [getExam, setGetExam] = useState([]);
  const [classes, setClasses] = useState([]);
  const studentData = JSON.parse(localStorage.getItem("student"));
  const pdfRef = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [answers, setAnswers] = useState({});
  const [examApiData, setExamApiData] = useState([]);

  const renderFile = (file) => {
    const fileExtension = file.split(".").pop().toLowerCase();
    const fileUrl = `${apiUrl}/${file}`;

    if (fileExtension === "pdf") {
      return (
        <div className="pdf-viewer">
          <Document
            file={fileUrl}
            onLoadSuccess={({ numPages }) => setTotalPages(numPages)}
            options={{ workerSrc: `pdf.worker.min.js` }}
          >
            {[...Array(totalPages)].map((_, index) => (
              <Page key={index} pageNumber={index + 1} />
            ))}
          </Document>
        </div>
      );
    } else if (["jpg", "jpeg", "png"].includes(fileExtension)) {
      return <img src={fileUrl} alt="Answer Sheet" className="answer-image" />;
    } else {
      return <div>Unsupported file type</div>;
    }
  };

  const openPdf = (pdfUrl) => {
    window.open(pdfUrl, "_blank");
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const offset = (currentPage - 1) * limit;
      try {
        const response = await fetch(
          `${apiUrl}/quiz/getQuiz?id=${id}&student_id=${studentData?.id}`
        );
        const result = await response.json();
        setExamApiData(result);
        if (result[0]?.submit_exams[0]?.upload_exams) {
          setAnswers(result[0]?.submit_exams[0]?.upload_exams);
        } else {
          setAnswers(result[0]?.submit_exams[0]?.submit_answersheet.split(","));
        }

        setTotalPages(Math.ceil(result.total_records / limit));
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [apiUrl, currentPage, limit, id]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${apiUrl}/question/exam-questions?exam_id=${id}`
        );
        const result = await response.json();
        setGetExam(result);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [apiUrl, id]);

  useEffect(() => {
    if (!loading && !download && getExam.length > 0) {
      setTimeout(generateAndOpenPdf, 2000);
      setDownload(true);
    }
  }, [loading, getExam]);

  useEffect(() => {
    const getClasses = async () => {
      try {
        const response = await fetch(
          `${apiUrlMain}/classlist.json?school_id=${studentData?.school_id}`
        );
        const result = await response.json();
        const filterClass = result?.classes?.filter(
          (v) => v?.id === studentData?.class
        );
        setClasses(filterClass);
      } catch (error) {
        console.error(error);
      }
    };
    if (studentData?.school_id) {
      getClasses();
    }
  }, [apiUrlMain]);

  const generateAndOpenPdf = () => {
    const element = pdfRef.current;
    const opt = {
      margin: 1,
      filename: "exam.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    html2pdf().from(element).set(opt).save();
  };

  const getSelectedAnswer = (questionIndex) => {
    const arrayOfArrays = Object.entries(answers);
    const jsonString = arrayOfArrays.map(([_, char]) => char).join("");
    let ans = {};
    try {
      ans = JSON.parse(jsonString);
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
    const answerKey = `Q${questionIndex}:`.trim();
    return ans[answerKey] || "";
  };

  return loading ? (
    <ThreeDots
      visible={true}
      height="80"
      width="80"
      color="#8e008e"
      radius="9"
      ariaLabel="three-dots-loading"
      wrapperStyle={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    />
  ) : (
    examApiData?.map((value, indexes) => (
      <>
        {examApiData[0]?.submit_exams[0]?.submit_answersheet !== null ? (
          examApiData[0]?.submit_exams[0]?.submit_answersheet.split(",")
            .length == 1 ? (
            <div className="answersheet-container">
              <iframe
                src={`${pdfUrl}${examApiData[0]?.submit_exams[0]?.submit_answersheet}`}
                width="100%"
                height="600px"
                title="PDF Viewer"
                frameBorder="0"
              >
                This browser does not support PDFs. Please download the PDF to
                view it:{" "}
                <a
                  href={`${pdfUrl}${examApiData[0]?.submit_exams[0]?.submit_answersheet}`}
                >
                  Download PDF
                </a>
                .
              </iframe>
            </div>
          ) : (
            <div className="answersheet-container">
              {answers.map((answer, i) => {
                console.log("df", answer); // Log each answer
                return (
                  <img
                    key={i}
                    src={`${pdfUrl}/${answer}`}
                    alt={`Answer ${i + 1}`}
                    className="answer-image"
                  />
                );
              })}
            </div>
          )
        ) : (
          <div className="pdf-exam-container" ref={pdfRef} key={indexes}>
            <div className="exam-header-container">
              <div className="profile-img">
                <img src={schoolLogo} alt="School Logo" />
                <span>Nancy School - 25 May</span>
              </div>
              <h4>
                Exams ({value?.exam_period}) Subject: {subject}
                Class: {classes[0]?.c_name}-{classes[0]?.c_section}(
                {classes[0]?.school_sections})
              </h4>
              <h4>
                Maximum Marks: {value?.max_marks} Start Time:{" "}
                {moment(value?.start_date, "DD-MM-YYYY").format("MMM DD, YYYY")}{" "}
                14:11 End Time:{" "}
                {moment(value?.end_date, "DD-MM-YYYY").format("MMM DD, YYYY")}{" "}
                14:12
              </h4>
            </div>
            {getExam.map((val, index) => {
              let optionsArray = [];
              try {
                optionsArray = JSON.parse(val.options) || [];
              } catch (error) {
                console.error("Error parsing options:", error);
              }

              if (examApiData[0]?.submit_exams[0]?.submit_answersheet == null) {
                const selectedAnswer = getSelectedAnswer(index + 1);
                return (
                  <div className="table-container" key={index}>
                    <div className="exam-container">
                      <div className="question-container">
                        <h5>
                          Question {index + 1}. {val.question}
                        </h5>
                        <h5>({val.marks})</h5>
                      </div>
                      <div className="option-container">
                        {val.ques_type === "objective" &&
                          optionsArray.length > 0 &&
                          optionsArray.map((option, i) => (
                            <div className="option-input-container" key={i}>
                              {selectedAnswer === option ? (
                                <div className="check-ans">
                                  <div className="check-ans-inner"></div>
                                </div>
                              ) : (
                                <div className="uncheck-ans">
                                  <div className="uncheck-ans-inner"></div>
                                </div>
                              )}
                              <label>{option}</label>
                            </div>
                          ))}
                        {val.ques_type === "subjective" && (
                          <h5>{selectedAnswer}</h5>
                        )}
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        )}
      </>
    ))
  );
};

export default GeneratePdf;
