// src/admin/Dashboard.js
import React, { useEffect, useState } from "react";
import "../css/style.css";
import Header from "../components/Header";
import Pagination from "../components/Pagination";
import { useMessages } from "../context/MessageContext";
import { Link } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import { ThreeDots } from "react-loader-spinner";

const Library = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [library, setLibrary] = useState([]);
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const { showSuccess, showError, clearMessages } = useMessages();
  const studentData = JSON.parse(localStorage.getItem("student"));
  const [sidebarToggle, setSidebarToggle] = useState(true);

  useEffect(() => {
    const getLibrary = async () => {
      setLoading(true);
      const sessionData = await JSON.parse(localStorage.getItem("student"));
      const formData = new FormData();
      formData.append("studentid", sessionData?.id);

      const requestOptions = {
        method: "POST",
        body: formData,
      };
      try {
        const response = await fetch(
          `${apiUrl}/librarydata.json`,
          requestOptions
        );
        const result = await response.json();
        const subjects = result.Subjects[0].subjects_name.split(",");
        const subjectIds = result.Subjects[0].subjects_ids.split(",");

        const subjectsArray = subjects.map((subject, index) => ({
          name: subject,
          id: subjectIds[index],
        }));

        setLibrary(subjectsArray);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getLibrary();
  }, [apiUrl]);

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const goBack = () => {
    window.history.back();
  };
  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="Class Library" click={sidebarOpen} />
        <div className="card_board">
          <div className="content_card_title flex-view">
            Library
            <button className="back-btn" onClick={goBack}>
              Back
            </button>
          </div>
          <div className="form-container">
            <div className="options">
              {loading ? (
                <ThreeDots
                  visible={true}
                  height="80"
                  width="80"
                  color="#8e008e"
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  wrapperClass=""
                />
              ) : (
                library.map((lib) => {
                  return (
                    <Link
                      key={lib.id}
                      to={`/dashboard/library-subject/${lib.id}`}
                    >
                      <button className="option" data-target="grades">
                        {lib.name}
                      </button>
                    </Link>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Library;
