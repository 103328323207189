// src/components/StudentProfile.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../components/Header";
import { useMessages } from "../context/MessageContext";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";

const StudentViewProfile = () => {
  const apiUrl = process.env.REACT_APP_API_NODE_URL;
  const { showSuccess, showError, clearMessages } = useMessages();
  const [sidebarToggle, setSidebarToggle] = useState(true);

  const [formData, setFormData] = useState({
    id: "",
    l_name: "",
    f_name: "",
    student_no: "",
    email: "",
    mobile_no: "",
    contactyoume: "",
    picture: null,
    opassword: "",
    password: "",
    cpassword: "",
  });

  useEffect(() => {
    const studentData = JSON.parse(localStorage.getItem("student"));
    console.log("sd", studentData, formData);

    if (studentData) {
      setFormData({
        id: studentData.id || "",
        l_name: studentData.l_name || "",
        f_name: studentData.f_name || "",
        student_no: studentData.adm_no || "",
        email: studentData.email || "",
        mobile_no: studentData.mobile_no || "",
        contactyoume: studentData.emergency_number || "",
        picture: null,
        opassword: "",
        password: "",
        cpassword: "",
      });
    }
  }, []);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "file" ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = new FormData();
    form.append("mobile_no", formData.mobile_no);

    form.append("contactyoume", formData.contactyoume);

    if (formData.picture) form.append("picture", formData.picture);

    if (formData.opassword) form.append("opassword", formData.opassword);
    if (formData.password) form.append("password", formData.password);
    if (formData.cpassword) form.append("cpassword", formData.cpassword);

    try {
      const response = await axios.put(
        `${apiUrl}/auth/updateProfile/${formData.id}`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data.message);
      showSuccess(response.data.message);
      // Handle success (e.g., show a success message)
    } catch (error) {
      console.error(error);
      showError(error.response.data);

      // Handle error (e.g., show an error message)
    }
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="Profile" click={sidebarOpen} />
        <div className="card_board">
          <div className="content_card_title flex-view">
            My Profile
            <button className="back-btn" onClick={goBack}>
              Back
            </button>
          </div>
          <div className="form-container">
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <input
                type="hidden"
                name="school_id"
                value={formData.school_id}
              />
              <input type="hidden" name="id" value={formData.id} />
              <div className="form-group form-control">
                <h4>Student Details</h4>
                <label>Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="l_name"
                  value={formData.l_name}
                  readOnly
                  placeholder="Last Name"
                />
              </div>
              <div className="form-group">
                <label>First Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="f_name"
                  value={formData.f_name}
                  readOnly
                  placeholder="First Name"
                />
              </div>
              <div className="form-group">
                <label>Student No</label>
                <input
                  type="text"
                  readOnly
                  className="form-control"
                  value={formData.student_no}
                  placeholder="Student No"
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  readOnly
                  className="form-control"
                  name="email"
                  value={formData.email}
                  placeholder="Email"
                />
              </div>
              <div className="form-group">
                <label>Contact No.</label>
                <input
                  type="text"
                  className="form-control"
                  name="mobile_no"
                  value={formData.mobile_no}
                  onChange={handleChange}
                  placeholder="Contact No."
                />
              </div>
              <div className="form-group">
                <label>You-Me Number</label>
                <input
                  type="text"
                  className="form-control"
                  name="contactyoume"
                  value={formData.contactyoume}
                  onChange={handleChange}
                  placeholder="You-Me Number"
                />
              </div>
              <div className="form-group">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label>Profile Image</label>
                  <img src="/school/img/" width="50px" height="50px" alt="" />
                </div>
                <input
                  type="file"
                  className="form-control"
                  name="picture"
                  onChange={handleChange}
                />
              </div>
              <h4>Change Password</h4>
              <div className="form-group">
                <label>Old Password</label>
                <input
                  type="password"
                  className="form-control"
                  name="opassword"
                  value={formData.opassword}
                  onChange={handleChange}
                  placeholder="Old Password"
                />
              </div>
              <div className="form-group">
                <label>New Password</label>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="New Password"
                />
              </div>
              <div className="form-group">
                <label>Confirm New Password</label>
                <input
                  type="password"
                  className="form-control"
                  name="cpassword"
                  value={formData.cpassword}
                  onChange={handleChange}
                  placeholder="Confirm New Password"
                />
              </div>
              <div className="col-md-12">
                <div className="error" id="stdnterror"></div>
                <div className="success" id="stdntsuccess"></div>
              </div>
              <button
                type="submit"
                id="editstdntprfbtn"
                className="btn btn-primary form-update"
              >
                Update
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentViewProfile;
