import React, { createContext, useState } from 'react';

const SubjectContext = createContext();

const SubjectProvider = ({ children }) => {
    const [subjectId, setSubjectId] = useState(null);

    return (
        <SubjectContext.Provider value={{ subjectId, setSubjectId }}>
            {children}
        </SubjectContext.Provider>
    );
};

export { SubjectContext, SubjectProvider };