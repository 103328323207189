// src/admin/Dashboard.js
import React, { useEffect, useState } from 'react';
import '../css/style.css';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { useParams } from 'react-router-dom';
import ContentTitle from '../components/ContentTitle';
import { ThreeDots } from 'react-loader-spinner';

const LibrarySubject = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [library, setLibrary] = useState([]);
  const [subject, setSubject] = useState('');
  const [classname, setClassname] = useState('');
  const [communityFilter, setCommunityFilter] = useState('newest');
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
}, [id]);


  const handleCommunityChange = (event) => {
    setCommunityFilter(event.target.value);
  };

  useEffect(() => {
    const getLibrary = async () => {
      setLoading(true)
      const studentData = await JSON.parse(localStorage.getItem('student'));
      const formData = new FormData();
      formData.append("subject_id", id);
      formData.append("class_id", studentData?.class);
      formData.append("school_id", studentData?.school_id);


      const requestOptions = {
        method: "POST",
        body: formData,
      };
      try {
        const response = await fetch(`${apiUrl}/library.json`, requestOptions);
        const result = await response.json();

        setSubject(result.subject_name);
        setClassname(result.class_name);
        setLibrary(result.content_details);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false)
      }
    };

    getLibrary();
  }, [apiUrl]);
  console.log(subject, library, classname);

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  }

  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}>
        <ContentTitle name="Library Content" click={sidebarOpen} />
        <div className='card_board'>
          {loading ?
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#8e008e"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
              wrapperClass=""
            /> : (
              <>
                <div className='content_card_title'>Library Content ({subject}( {classname}))
                  <div className='selectboxes'>
                    <select
                      className="form-control community_filter select2-hidden-accessible"
                      id="comm_filter"
                      onChange={handleCommunityChange}
                      value={communityFilter}
                    >
                      <option value="newest">Newest</option>
                      <option value="pdf">PDF</option>
                      <option value="video">Video</option>
                      <option value="word">Word Document</option>
                      <option value="audio">Audio</option>
                    </select>
                    <button className='back-btn' onClick={goBack}>Back</button>
                  </div>
                </div>
                <div className="row viewtutcontent" id="viewtutcontent" style={{ padding: "0px 20px" }}>
                  {library?.map((lib) => (
                    <div className="col-sm-2 col_img">
                      <a href={`/dashboard/library-content/${lib.id}`} title="view" className='viewknow'>
                        <img
                          src="http://you-me-globaleducation.org/youme-logo.png"
                          style={{ padding: '83px 0', border: '1px solid #cccccc', width: '200px' }}
                          alt="YouMe Logo"
                        />
                        <div className="set_icon">
                        <i className={`${lib?.file_type === "pdf" ? "bi bi-file-pdf" : lib?.file_type === "video" ? " bi bi-camera-video" : lib?.file_type === "audio" ? "bi bi-headphones" : "bi bi-filetype-doc"}`}></i>
                        </div>
                      </a>
                      <p className="title" style={{ color: '#000' }}>
                        <b>Titre</b>:{lib.title}
                      </p>
                    </div>
                  ))}
                </div>
              </>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default LibrarySubject;
