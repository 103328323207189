import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import Header from "../components/Header";
import { Link, useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { useMessages } from "../context/MessageContext";

const ViewExamInfo = () => {
  const { examId } = useParams();
  const { studentId } = useParams();
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [quizData, setQuizData] = useState([]);
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_NODE_URL;
  const sessionData = JSON.parse(localStorage.getItem("student"));
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedField, setSelectedField] = useState("");
  const [addField, setAddFiled] = useState(false);
  const [imageVal, setImageVal] = useState([]);
  const [imageVal2, setImageVal2] = useState(null);
  const { showSuccess, showError, clearMessages } = useMessages();
  const [imageFields, setImageFields] = useState([{ id: 1 }]);
  const apacheUrl = process.env.REACT_APP_APACHE;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [examId, studentId]);

  const addImageField = () => {
    setImageFields([...imageFields, { id: imageFields.length + 1 }]); // Add new input field
  };

  const removeImageField = (id) => {
    setImageFields(imageFields.filter((field) => field.id !== id)); // Remove input field by id
  };

  useEffect(() => {
    const getDRopboxView = async () => {
      const sessionData = await JSON.parse(localStorage.getItem("student"));
      setLoading(true);
      const requestOptions = {
        method: "GET",
      };
      try {
        const response = await fetch(
          `${apiUrl}/quiz/getQuiz?id=${examId}&student_id=${sessionData?.id}`,
          requestOptions
        );
        const result = await response.json();
        setQuizData(result); // Assuming your API returns an array of subjects
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getDRopboxView();
  }, [apiUrl, examId]);

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const goBack = () => {
    window.history.back();
  };

  const handleShowModal = (quiz) => {
    setSelectedQuiz(quiz);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedField("");
    setAddFiled(false);
    setShowModal(false);
    setSelectedQuiz(null);
  };

  const onSelectedField = (e) => {
    setSelectedField(e.target.value);
  };

  const getImageFile = (e) => {
    setImageVal((prevState) => [...prevState, e.target.files[0]]);
  };

  const submitQuize = async () => {
    setLoading(true);
    const sessionData = await JSON.parse(localStorage.getItem("student"));

    const authToken = "vWEMNrj5R8aKwZYYZoxcUMDWk5OnHf";
    const formData = new FormData();

    if (imageVal.length > 0) {
      imageVal?.map((val) => formData.append("file_name", val));
    } else if (setImageVal2 !== null) {
      formData.append("file_name", imageVal2);
    }
    formData.append("status", 1);
    formData.append("file_type", selectedField);

    formData.append("student_id", sessionData?.id);
    formData.append("exam_id", quizData[0].id);
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: formData,
    };

    try {
      const response = await fetch(
        `${apiUrl}/exam/submitExamUpdate/${quizData[0].submit_exams[0].id}`,
        requestOptions
      );
      const result = await response.json();
      if (response.ok) {
        showSuccess(`${result.message}: Exam Uploaded`);
      } else {
        showError(result.message);
      }
      setImageVal("");
      handleCloseModal();
    } catch (error) {
      console.error(error);
      showError(error);
    } finally {
      setLoading(false);
      setTimeout(() => {
        clearMessages();
      }, 3000);
    }
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="Exam Detail" click={sidebarOpen} />
        {loading ? (
          <ThreeDots
            visible={true}
            height="80"
            width="80"
            color="#8e008e"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            wrapperClass=""
          />
        ) : (
          <>
            <div className="viewQuiz-title">
              <h5>
                Name:{" "}
                <span style={{ fontWeight: "lighter", marginRight: "10px" }}>
                  {sessionData?.f_name} {sessionData?.l_name}
                </span>
                Title:{" "}
                <span style={{ fontWeight: "lighter" }}>
                  {quizData[0]?.title}
                </span>
              </h5>
              <div className="quiz-view-btn-container">
                <button onClick={(quiz) => handleShowModal(quiz)}>
                  <i
                    className="bi bi-upload"
                    style={{ marginRight: "5px" }}
                  ></i>{" "}
                  Answersheet
                </button>
                <Link
                  to={`${apacheUrl}uploadExams/${quizData[0]?.file_name}`}
                  target="_blank"
                  download
                >
                  <i
                    className="bi bi-download"
                    style={{ marginRight: "5px" }}
                  ></i>
                  Exam
                </Link>
                <button onClick={goBack}>Back To Listing</button>
              </div>
            </div>
            <iframe
              src={`${apacheUrl}?studid=${sessionData?.id}&classId=${sessionData?.class}&p=quiz&subId=${studentId}&submittedQuizmode=31507&submittedQuizHash=${quizData[0]?.file_name}&returnUrl=L3NjaG9vbC9xdWl6`}
              title="iframe link"
              style={{ width: "100%", height: "1000px" }}
            ></iframe>
          </>
        )}
      </div>

      {/* Custom Modal */}
      {showModal && (
        <div className="custom-modal">
          <div className="custom-modal-content">
            <div className="custom-modal-title">
              Upload Quiz{" "}
              <i
                className="bi bi-x-lg"
                onClick={handleCloseModal}
                style={{ cursor: "pointer" }}
              ></i>
            </div>
            {selectedQuiz && (
              <>
                <h5>Upload Quiz</h5>
                <select onChange={(e) => onSelectedField(e)}>
                  <option>--Select Type--</option>
                  <option value="pdf">Pdf</option>
                  <option value="images">Image</option>
                </select>

                {selectedField === "images" && (
                  <>
                    {imageFields.map((field) => (
                      <div
                        key={field.id}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <input
                          type="file"
                          accept="image/png, image/gif, image/jpeg"
                          className="custom-modal-file"
                          onChange={(e) => getImageFile(e, field.id)}
                        />
                        <i
                          className="bi bi-dash"
                          style={{ cursor: "pointer" }}
                          onClick={() => removeImageField(field.id)}
                        ></i>
                      </div>
                    ))}
                    <i
                      className="bi bi-plus"
                      style={{ cursor: "pointer" }}
                      onClick={addImageField}
                    ></i>
                  </>
                )}

                {selectedField === "pdf" && (
                  <input
                    type="file"
                    accept="application/pdf"
                    className="custom-modal-file"
                    onChange={(e) => setImageVal2(e.target.files[0])}
                  />
                )}

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button className="back-btn" onClick={submitQuize}>
                    Upload Quiz
                  </button>
                  <span style={{ margin: "5px" }}></span>
                  <button className="back-btn" onClick={handleCloseModal}>
                    Close
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewExamInfo;
