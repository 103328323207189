import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import { useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { useMessages } from "../context/MessageContext";

const StudentMessageView = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [loading, setLoading] = useState(false);
  const [messageView, setMessageView] = useState([]);
  const [messageTitleObj, setMessageTitleObj] = useState({});
  const [textareaOpen, setTextareaOpen] = useState(false);
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;

  const [replyIndex, setReplyIndex] = useState(null);
  const [replyText, setReplyText] = useState("");
  const sessionData = JSON.parse(localStorage.getItem("student"));
  const { showSuccess, showError, clearMessages } = useMessages();
  const { messageId } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [messageId]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const sessionData = await JSON.parse(localStorage.getItem("student"));
      const formData = new FormData();
      formData.append("message_id", messageId);
      formData.append("school_id", sessionData?.school_id);

      const requestOptions = {
        method: "POST",
        body: formData,
      };
      try {
        const response = await fetch(
          `${apiUrl}/viewcontactschool.json`,
          requestOptions
        );
        const result = await response.json();
        setMessageView(result?.all_messages);
        setMessageTitleObj(result?.get_messages);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [apiUrl]);

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const OpentextAreaModal = (index) => {
    setTextareaOpen(true);
    setReplyIndex(index);
  };

  const ClosetextAreaModal = () => {
    setTextareaOpen(false);
  };

  const handleTextChange = (e) => {
    setReplyText(e.target.value);
  };

  // Function to handle sending the reply text
  const handleSendReply = async () => {
    if (!replyText.trim()) {
      alert("Please enter a reply before sending.");
      return;
    }
    const formData = new FormData();
    formData.append("message_id", messageId);
    formData.append("school_id", sessionData?.school_id);
    formData.append("student_id", sessionData?.id);
    formData.append("message", replyText);

    const requestOptions = {
      method: "POST",
      body: formData,
    };
    // Example API call to send the reply text
    try {
      const response = await fetch(
        `${apiUrl}/addcontactmsgreply.json`,
        requestOptions
      );

      if (response.ok) {
        const result = await response.json();
        setTimeout(() => {
          showSuccess(result.message);
          setReplyText("");
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      console.error(error);
      showError(error);
    }
  };
  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="Contact School" click={sidebarOpen} />
        <div className="card_board">
          {loading ? (
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#8e008e"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              wrapperClass=""
            />
          ) : (
            <>
              <div className="content_card_title flex-view">
                {messageTitleObj?.subject}{" "}
                <button className="back-btn" onClick={goBack}>
                  Back
                </button>
              </div>
              {messageView?.map((val, index) => (
                <div className="allMessage_container" key={index}>
                  <div className="container-main">
                    <div className="message_sender_container">
                      <h4>
                        {val?.sender} - {val?.student_no} - (Class:{" "}
                        {val?.classname})
                      </h4>
                      <p>
                        {val?.message} <span>(Delivered)</span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}

              {/* Reply button and text area */}
              {textareaOpen ? (
                <div className="reply_container">
                  <textarea
                    name="reply_text"
                    value={replyText}
                    onChange={handleTextChange}
                    placeholder="Type your reply here..."
                  ></textarea>
                  <div className="btn-row">
                    <button
                      className="send-btn"
                      onClick={() => handleSendReply()}
                    >
                      <i
                        className="bi bi-send-check"
                        style={{ marginRight: "5px" }}
                      ></i>
                      Send
                    </button>
                    <div className="bottom-right-btn">
                      <i
                        className="bi bi-three-dots-vertical"
                        style={{ marginRight: "8px" }}
                      ></i>
                      <i
                        className="bi bi-trash3-fill"
                        onClick={ClosetextAreaModal}
                      ></i>
                    </div>
                  </div>
                </div>
              ) : (
                <button
                  className="reply-btn"
                  onClick={() => OpentextAreaModal()}
                >
                  <i
                    className="bi bi-reply-fill"
                    style={{ marginRight: "5px" }}
                  ></i>
                  Reply
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentMessageView;
