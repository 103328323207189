import React from 'react';
import PropTypes from 'prop-types';
import '../css/Message.css';

const ErrorMessage = ({ message }) => {
  if (!message) return null; // Don't render if no message

  return (
    <div className="error-message">
      {message}
    </div>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired
};

export default ErrorMessage;
